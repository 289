<script setup>
import { Head, Link, useForm, router, usePage } from "@inertiajs/vue3"
import AuthenticationCard from "@/Components/AuthenticationCard.vue"
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue"
import Checkbox from "@/Components/Checkbox.vue"
import InputError from "@/Components/InputError.vue"
import InputLabel from "@/Components/InputLabel.vue"
import PrimaryButton from "@/Components/PrimaryButton.vue"
import TextInput from "@/Components/TextInput.vue"
import { onMounted, computed } from "vue"

defineProps({
    canResetPassword: Boolean,
    status: String,
})

const form = useForm({
    username: "",
    password: "",
    remember: false,
})

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    })
}

const page = usePage()

onMounted(() => {
    if (route().params.username) {
        form.username = route().params.username
    }
})

const autoFocusUsername = computed(() => {
    return route().params.username ? false : true
})

const autoFocusPassword = computed(() => {
    return route().params.username ? true : false
})
</script>

<template>
    <Head title="Log in" />
    <pre>{{ page.url.params }}</pre>
    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>

        <div
            v-if="status"
            class="mb-4 font-medium text-sm text-green-600 dark:text-green-400">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <InputLabel
                    for="username"
                    value="Username" />
                <TextInput
                    id="username"
                    v-model="form.username"
                    type="text"
                    class="mt-1 block w-full"
                    required
                    :autofocus="autoFocusUsername"
                    autocomplete="username" />
                <InputError
                    class="mt-2"
                    :message="form.errors.username" />
            </div>

            <div class="mt-4">
                <InputLabel
                    for="password"
                    value="Password" />
                <TextInput
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="mt-1 block w-full"
                    required
                    :autofocus="autoFocusPassword"
                    autocomplete="current-password" />
                <InputError
                    class="mt-2"
                    :message="form.errors.password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <Checkbox
                        v-model:checked="form.remember"
                        name="remember" />
                    <span class="ms-2 text-sm text-gray-600 dark:text-gray-400">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <Link
                    v-if="canResetPassword"
                    :href="route('password.request')"
                    class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800">
                    Forgot your password?
                </Link>

                <PrimaryButton
                    class="ms-4"
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing">
                    Log in
                </PrimaryButton>
            </div>
        </form>
    </AuthenticationCard>
</template>
